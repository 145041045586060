body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ibm-plex {
  font-family: 'IBM Plex Mono', monospace;
}
.work-sans {
  font-family: 'Work Sans', sans-serif;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.overlay {
  backdrop-filter: blur(10px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur(10px) saturate(160%) contrast(45%) brightness(140%);
}


.weboverlay {
  background-color: rgba(52, 52, 52, 0.5);
  backdrop-filter: blur(2px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur(2px) saturate(160%) contrast(45%) brightness(140%);
}


.tabs {
  color: black;
  background-color: #dddddd;
  padding: 8px 20px;
  border-radius: 25px;
}


.active-tabs {
  color: white;
  background-color: #8B74D3;
  padding: 8px 20px;
  border-radius: 25px;
}

.tabss {
  color: #7f7f7f;
  background-color: #ebebeb;
  padding: 8px 20px;
  border-radius: 25px;
}



.active-tabss {
  color: white;
  background-color: #8B74D3;
  padding: 8px 20px;
  border-radius: 25px;
}

.content {
  display: none;
}
.active-content {
  display: block;
}




@tailwind base;
@tailwind components;
@tailwind utilities;
